<template>
    <div id="document-layout" class="flex min-h-screen">
        <div class="flex w-full flex-1 flex-col">
            <header class="sticky top-0 z-10">
                <slot name="header">
                    <AppHeader />
                </slot>
            </header>

            <main class="px-2 pb-6 pt-4">
                <div
                    class="relative mx-auto flex w-full flex-col items-start gap-8 px-2 pt-4 lg:flex-row lg:gap-4"
                    :class="
                        slots.settings && slots.attachments
                            ? 'max-w-[1920px]'
                            : slots.settings
                              ? 'max-w-[1440px]'
                              : 'max-w-7xl'
                    "
                >
                    <!-- Settings -->
                    <div
                        v-if="slots.settings"
                        class="w-full lg:sticky lg:top-[calc(var(--nav-height)+2rem)] lg:w-72 lg:min-w-72"
                    >
                        <slot name="settings" />
                    </div>

                    <!-- If we have both "default" and "attachments" slots, use Resizable -->
                    <BaseResizable
                        v-if="slots.default && slots.attachments"
                        class="h-full w-full lg:flex-row"
                        :cache-key="resizableCacheKey"
                        :min-width="{ left: 400, right: 250 }"
                    >
                        <!-- Middle: Form -->
                        <template #left>
                            <slot />
                        </template>

                        <!-- Right: Attachments -->
                        <template #right>
                            <div
                                class="sticky top-[calc(var(--nav-height)+2rem)] flex h-full max-h-[calc(100vh-var(--nav-height)-3.5rem)] min-h-[calc(100vh-var(--nav-height)-3.5rem)] flex-col"
                            >
                                <slot name="attachments" />
                            </div>
                        </template>
                    </BaseResizable>

                    <div v-else class="w-full min-w-0">
                        <slot />
                    </div>
                </div>
            </main>
        </div>
    </div>
</template>

<script setup lang="ts">
defineProps({
    resizableCacheKey: {
        type: String,
        default: '',
    },
})

const slots = useSlots()
</script>
